<template>
    <div class="icon-progress-going">
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <rect x="1.5" y="1" width="30" height="30" rx="15" fill="#A7D0F8" />
            <rect x="1.5" y="1" width="30" height="30" rx="15" stroke="#A7D0F8" stroke-width="2" />
            <circle cx="16.5" cy="16" r="5" fill="white" />
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style>
    .icon-progress-going {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>